import React from 'react'

const PodcastCTA = ({ primary }) => {
    return (
        <div className={`call-to-action ${primary ? 'primary-action' : ''}`}>
            <h3 className='title'>Subscribe to Our Podcast</h3>
            <p>Stay informed about our latest episodes, exclusive interviews, and exclusive content.</p>
            <form>
                <input type="email" placeholder="Enter your email address" />
                <button type="submit">Subscribe</button>
            </form>

        </div>
    )
}

export default PodcastCTA
