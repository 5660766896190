export const articlesList = [
    {
        title: "Set Video Playback Speed With Javascript",
        slug: "set-video-playback-speed-with-javascript",
        category: "Travel",
        author: "Jenny Kiaa",
        date: "02 December 2022",
        readingTime: "3 Min",
        description: "Did you come here for something in particular or just general Riker-bashing? And blowing into",
        isFeatured: true,
        type: "Featured",
        thumbnail: "https://plus.unsplash.com/premium_photo-1672423156257-9a2bc5e1f480?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        title: "Design Is The Mix Of Emotions",
        slug: "design-is-the-mix-of-emotions",
        category: "Travel",
        author: "Jenny Kiaa",
        date: "02 December 2022",
        readingTime: "3 Min",
        description: "Did you come here for something in particular or just general Riker-bashing? And blowing into",
        isFeatured: true,
        type: "Featured",
        thumbnail: "https://plus.unsplash.com/premium_photo-1672423156257-9a2bc5e1f480?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        title: "Design Is The Mix Of Emotions",
        slug: "design-is-the-mix-of-emotions",
        category: "Travel",
        author: "Jenny Kiaa",
        date: "02 December 2022",
        readingTime: "3 Min",
        description: "Did you come here for something in particular or just general Riker-bashing? And blowing into",
        isFeatured: false,
        type: "Popular",
        thumbnail: "https://plus.unsplash.com/premium_photo-1672423156257-9a2bc5e1f480?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        title: "Set Video Playback Speed With Javascript",
        slug: "set-video-playback-speed-with-javascript",
        category: "Travel",
        author: "Jenny Kiaa",
        date: "02 December 2022",
        readingTime: "3 Min",
        description: "Understand how to control video playback speeds programmatically with simple code.",
        isFeatured: false,
        type: "Popular",
        thumbnail: "https://plus.unsplash.com/premium_photo-1672423156257-9a2bc5e1f480?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        title: "Exploring the Depth of Design",
        slug: "exploring-the-depth-of-design",
        category: "Design",
        author: "Michael Doe",
        date: "15 November 2022",
        readingTime: "5 Min",
        description: "An in-depth exploration into the blend of design and human emotion.",
        isFeatured: true,
        type: "Featured",
        thumbnail: "https://plus.unsplash.com/premium_photo-1672423156257-9a2bc5e1f480?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        title: "Unleashing Creative Code",
        slug: "unleashing-creative-code",
        category: "Programming",
        author: "John Smith",
        date: "08 October 2022",
        readingTime: "7 Min",
        description: "How creative programming shapes innovative solutions in tech and beyond.",
        isFeatured: false,
        type: "Popular",
        thumbnail: "https://plus.unsplash.com/premium_photo-1672423156257-9a2bc5e1f480?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        title: "Traveling Through Typography",
        slug: "traveling-through-typography",
        category: "Travel",
        author: "Jenny Kiaa",
        date: "22 September 2022",
        readingTime: "4 Min",
        description: "Typography in travel blogging: balancing aesthetics and readability.",
        isFeatured: false,
        type: "Popular",
        thumbnail: "https://plus.unsplash.com/premium_photo-1672423156257-9a2bc5e1f480?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        title: "Nature Meets Minimalism",
        slug: "nature-meets-minimalism",
        category: "Lifestyle",
        author: "Emily Rose",
        date: "12 August 2022",
        readingTime: "6 Min",
        description: "How minimalist designs are inspired by the natural world.",
        isFeatured: true,
        type: "Featured",
        thumbnail: "https://plus.unsplash.com/premium_photo-1672423156257-9a2bc5e1f480?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        title: "Programming Paradigms Redefined",
        slug: "programming-paradigms-redefined",
        category: "Programming",
        author: "John Smith",
        date: "05 July 2022",
        readingTime: "8 Min",
        description: "Understanding the shift in modern programming paradigms.",
        isFeatured: false,
        type: "Popular",
        thumbnail: "https://plus.unsplash.com/premium_photo-1672423156257-9a2bc5e1f480?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        title: "Evolving UI Trends",
        slug: "evolving-ui-trends",
        category: "Design",
        author: "Michael Doe",
        date: "01 June 2022",
        readingTime: "5 Min",
        description: "An insight into the current evolution of UI/UX trends and practices.",
        isFeatured: false,
        type: "Popular",
        thumbnail: "https://plus.unsplash.com/premium_photo-1672423156257-9a2bc5e1f480?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        title: "Capturing Stories in Code",
        slug: "capturing-stories-in-code",
        category: "Technology",
        author: "John Smith",
        date: "25 May 2022",
        readingTime: "6 Min",
        description: "The art of embedding storytelling in software development.",
        isFeatured: true,
        type: "Featured",
        thumbnail: "https://plus.unsplash.com/premium_photo-1672423156257-9a2bc5e1f480?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
];
