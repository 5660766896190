import React, { useEffect, useState } from 'react'
import PageHeader from '../components/PageHeader'
import { useLocation } from 'react-router-dom'
import { Calendar01Icon, Clock01Icon, UserIcon } from 'hugeicons-react'
import { SlicedText } from '../components/SlicedText'
import { ArticleCard } from '../components/ArticleCard'

const SearchResultsPage = () => {
    const location = useLocation()
    const { title, results } = location.state || {}

    return (
        <div className='archive-page'>
            <PageHeader />
            <div className="archive-content container">
                <h1 className='section-title'>Results for <span>{title}</span> </h1>
                <hr />
                <div className="articles-list">
                    {
                        results && results?.map((article, index) => (
                            <ArticleCard article={article} index={index} />
                            // <div key={index} className="article">
                            //     <div className="image">
                            //         <img className='thumbnail' src={article.thumbnail} alt={article.title} />
                            //     </div>

                            //     <div className="article-content">
                            //         <div className="article-content">
                            //             <p className='category'>{article.category}</p>
                            //             <p className='title'>{article.title}</p>
                            //             <div className="metas">
                            //                 <div className="meta author">
                            //                     <div className="icon">
                            //                         <UserIcon size={15} />
                            //                     </div>
                            //                     <small>{article.author}</small>
                            //                 </div>
                            //                 <div className="meta date">
                            //                     <div className="icon">
                            //                         <Calendar01Icon size={15} />
                            //                     </div>
                            //                     <small>{article.date}</small>
                            //                 </div>
                            //                 <div className="meta read-time">
                            //                     <div className="icon">
                            //                         <Clock01Icon size={15} />
                            //                     </div>
                            //                     <small>{article.readingTime} <span>To Read</span></small>
                            //                 </div>
                            //             </div>
                            //             <div className="excerpts">
                            //                 <p><SlicedText text={article.description} length={100} /></p>
                            //             </div>
                            //         </div>
                            //     </div>
                            // </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default SearchResultsPage
