import React, { useState } from 'react'
import { CategoriesList } from './CategoriesList'
import PodcastCTA from './PodcastCTA'
import { fetchCategories } from '../services/api'

const SideBar = () => {
    const [categories, setCategories] = useState([])

    const fetchCategoriesData = async () => {
        const response = await fetchCategories()
        if (response.success) {
            setCategories(response.categories)
        }
    }
    fetchCategoriesData()
    return (
        <div className="sidebar">
            <CategoriesList categories={categories} />
            <PodcastCTA primary={true} />
        </div>
    )
}

export default SideBar
