import { Calendar01Icon, Clock01Icon, UserIcon } from 'hugeicons-react'
import React from 'react'
import { SlicedText } from './SlicedText'
import '../assets/scss/articlesList/articlesList.css'
import { ArticleCard } from './ArticleCard'
const MarketsSection = ({ articles }) => {
    return (
        <section className='markets section'>
            <h2 className='section-title'> <span>Markets</span> Insights</h2>
            <div className="articles-list">
                {
                    articles && articles?.slice(0, 3).map((article, index) => (
                        <ArticleCard article={article} index={index} />
                    ))
                }
            </div>
        </section>
    )
}

export default MarketsSection

