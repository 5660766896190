import { Mic01Icon, PlayCircle02Icon, StartUp01Icon } from 'hugeicons-react'
import React from 'react'
import { SlicedText } from './SlicedText'
import { Link } from 'react-router-dom'

const PodcastCard = ({ podcast, index }) => {
    return podcast ? (
        <Link to={podcast.url} target='blank' key={index} className="podcast">
            <div className="image">
                <div className="overlay">
                    <PlayCircle02Icon />
                </div>
                <img className='thumbnail' src={podcast.thumbnail} alt={podcast.title} />
            </div>
            <div className="podcast-content">
                <p className='category'>{podcast.category}</p>
                <p className='title'>{podcast.title}</p>
                <p className='description'><SlicedText text={podcast.description} length={30} /></p>

                <div className="metas">
                    <div className="meta author">
                        <div className="icon">
                            <Mic01Icon size={15} />
                        </div>
                        <span>{podcast.host}</span>
                    </div>

                    <div className="meta read-time">
                        <div className="icon">
                            <StartUp01Icon size={15} />
                        </div>
                        <span>{podcast.rating}</span>
                    </div>
                </div>
            </div>
        </Link>
    ) : ''
}

export default PodcastCard
