import { Calendar01Icon, Clock01Icon, UserIcon } from 'hugeicons-react'
import React from 'react'
import { SlicedText } from './SlicedText'
import { ArticleCard } from './ArticleCard'

const TechSection = ({ articles }) => {
    return (
        <section className='tech-section'>
            <h2 className='section-title'> <span>Tech</span> News</h2>
            <div className="articles-list">
                {
                    articles && articles?.slice(0, 7).map((article, index) => (
                        <ArticleCard article={article} index={index} />
                    ))
                }
            </div>
        </section>
    )
}

export default TechSection
