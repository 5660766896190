import React from 'react'

const NewLetterCTA = ({ primary }) => {
    return (
        <div className={`call-to-action ${primary ? 'primary-action' : ''}`}>
            <h3 className='title'>Subscribe to Our Newsletter</h3>
            <p>Stay up-to-date with our latest news, articles, and exclusive offers.</p>
            <form>
                {/* <input type="text" placeholder='Full name' /> */}
                <input type="email" placeholder="Enter your email address" />
                <button type="submit">Subscribe</button>
            </form>
        </div>
    )
}

export default NewLetterCTA
