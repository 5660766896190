import { Cancel01Icon, Loading03Icon, Search01Icon } from 'hugeicons-react';
import React, { useState } from 'react';
import { searchArticles } from '../services/api';
import { useNavigate } from 'react-router-dom';

const SearchArticles = ({ toggleSearch }) => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [clickedSearch, setClickedSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    const handleSearch = async (e) => {
        e.preventDefault();
        setArticles([]);
        setLoading(true);
        setClickedSearch(true);

        const results = await searchArticles(searchTerm);
        setLoading(false);
        if (results.success && results.articles.length > 0) {
            toggleSearch()
            navigate(`/articles/search/results/`, {
                state: { results: results.articles, title: searchTerm },
            });
        } else {
            setArticles([]);
        }
    };

    return (
        <div className="popup">
            <div className="popup-content">
                <Cancel01Icon className="close-icon" onClick={toggleSearch} />
                <h2>Search Articles</h2>
                <form>
                    <input
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        type="search"
                        placeholder="Type to search"
                    />
                    <button
                        onClick={(e) => handleSearch(e)}
                        className="primary-button"
                    >
                        {loading ? (
                            <Loading03Icon className="loading-icon" />
                        ) : (
                            <Search01Icon />
                        )}{" "}
                        Search
                    </button>
                </form>


                {clickedSearch && !loading && articles.length === 0 && (
                    <p>No articles found with the search term.</p>
                )}
            </div>
        </div>
    );
};

export default SearchArticles;
