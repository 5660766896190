import React, { useEffect, useState } from 'react'
import '../assets/scss/components/heroSection/heroSection.css';
import { Calendar01Icon, Clock01Icon, Mic01Icon, PlayCircle02Icon, StartUp01Icon, UserIcon } from 'hugeicons-react';
import { SlicedText } from './SlicedText';
import PodcastCTA from './PodcastCTA';
import PodcastCard from './PodcastCard';
import { fetchArticles, fetchPodcasts } from '../services/api';
import { ArticleCard2 } from './ArticleCard';

const HeroSection = ({ articles, podcasts }) => {

    return (
        <div className='hero-section'>
            <div className="container">
                <div className="articles-container">
                    <h2 className='section-title'><span>Most</span>Popular</h2>
                    <div className="articles">
                        {
                            articles && articles?.slice(0, 2).map((article, index) => (
                                <ArticleCard2 article={article} index={index} />
                            ))
                        }
                    </div>
                </div>
                <div className="mobile-call-to-action">
                    <PodcastCTA primary={true} />
                </div>
                <div className="podcasts-container">
                    <h2 className='section-title'><span>Latest</span> Podcasts</h2>
                    <div className="podcasts">
                        {
                            podcasts && podcasts?.slice(0, 4).map((podcast, index) => (
                                <PodcastCard podcast={podcast} index={index} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection
