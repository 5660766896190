import { FaceIdIcon, InstagramIcon, Linkedin01Icon, NewTwitterIcon } from 'hugeicons-react'
import React from 'react'
import { Helmet } from 'react-helmet'

const ComingBackSoon = () => {
  return (
    <>
      <Helmet>
        <title>Coming soon | The Kfinancial</title>
      </Helmet>
      <div className="coming-soon-page">
        <div className="overlay">

        </div>
        <div className="container">
          <img className='logo' src="/logo512.png" alt="" />
          <h1>Coming soon</h1>
          <p>
            We're thrilled to announce that The Kfinancial is excited to announce the launch of our new website!
            We're currently working on some exciting features and improvements, so please check back soon.
          </p>
          <div className="buttons">
            <a target='_blank' className='button primary-button' href="https://forms.gle/BeiSxyfhfmo7LXMe7">Join the waiting list</a>
            <a className='button outline-button' href="/home/">Vew the progress</a>
          </div>
        </div>

        <div className="social-links">
          <div className="links">
            <a href="https://www.linkedin.com/posts/the-k-financial-news_a-look-at-the-cost-of-borrowing-in-uganda-activity-7241687423443714048-ejmT?utm_source=share&utm_medium=member_ios"><Linkedin01Icon /></a>
            <a href="https://www.instagram.com/thekfinancial?igsh=NXM3MWV1aGZ4OXU="><InstagramIcon /></a>
            <a href="https://x.com/kfinancialnews?s=11"><NewTwitterIcon /></a>
          </div>
        </div>
      </div>
    </>
  )
}

export default ComingBackSoon
