import axios from "axios";
import { articlesList } from "../constants/articlesList";
import { podcastsList } from "../constants/podCasts";
import { categoriesList } from "../constants/categories";

export const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: API_URL
})

api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("access");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        } return config
    }, (error) => {
        return Promise.reject(error)
    }
)

export default api

export const cleanedData = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
            acc[key] = value;
        }
        return acc;
    }, {});
};



// service to fetch articles

export const fetchArticles = async () => {
    // for testing purposes
    return {
        success: true,
        articles: articlesList,
        message: "Articles fetched successfully",
    }
    try {
        const response = await axios.get("/articles");
        return {
            success: true,
            articles: response.data,
            message: "Articles fetched successfully",
        };
    } catch (error) {
        console.log(error)
        return {
            success: false,
            articles: [],
            message: error.response?.data?.message || error.response?.data?.error || "Failed to fetch articles",
        };

    }
};


// service to fetch podcasts

export const fetchPodcasts = async () => {
    // for testing purposes
    return {
        success: true,
        podcasts: podcastsList,
        message: "Podcasts fetched successfully",
    }
    try {
        const response = await axios.get("/podcasts");
        return {
            success: true,
            podcasts: response.data,
            message: "Podcasts fetched successfully",
        };
    } catch (error) {
        console.log(error)
        return {
            success: false,
            podcasts: [],
            message: error.response?.data?.message || error.response?.data?.error || "Failed to fetch podcasts",
        };
    }
};


// service to fetch categories

export const fetchCategories = async () => {
    // for testing purposes
    return {
        success: true,
        categories: categoriesList,
        message: "Categories fetched successfully",
    }
    try {
        const response = await axios.get("/categories");
        return {
            success: true,
            categories: response.data,
            message: "Categories fetched successfully",
        };
    } catch (error) {
        console.log(error)
        return {
            success: false,
            categories: [],
            message: error.response?.data?.message || error.response?.data?.error || "Failed to fetch categories",
        };
    }
}


// api to handle search. for testing purposes we are going to filter the articles based on title, and description

export const searchArticles = async (searchTerm) => {
    // for testing purposes
    console.log('search term: ', searchTerm)
    return {
        success: true,
        articles: articlesList.filter((article) =>
            article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            article.description.toLowerCase().includes(searchTerm.toLowerCase())
        ),
        message: "Search results fetched successfully",
    }
    try {
        const response = await axios.get(`/articles?search=${searchTerm}`);
        return {
            success: true,
            articles: response.data,
            message: "Search results fetched successfully",
        };
    } catch (error) {
        console.log(error)
        return {
            success: false,
            articles: [],
            message: error.response?.data?.message || error.response?.data?.error || "Failed to fetch search results",
        };
    }
};