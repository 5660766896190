export const podcastsList = [
    {
        id: 1,
        title: "Tech Trends Today",
        host: "Alex Carter",
        description: "Exploring the latest in technology and innovation.",
        thumbnail: "https://images.unsplash.com/photo-1531651008558-ed1740375b39?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 56,
        category: "Technology",
        rating: 4.8,
        url: "https://substack.com/",
    },
    {
        id: 2,
        title: "Startup Stories",
        host: "Sophia Zhang",
        description: "Inspiring journeys of entrepreneurs building their dreams.",
        thumbnail: "https://images.unsplash.com/photo-1488376986648-2512dfc6f736?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 34,
        category: "Business",
        rating: 4.5,
        url: "https://substack.com/",
    },
    {
        id: 3,
        title: "Mindful Moments",
        host: "Ryan Taylor",
        description: "Guided meditations and insights for a balanced life.",
        thumbnail: "https://images.unsplash.com/photo-1531651008558-ed1740375b39?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 48,
        category: "Wellness",
        rating: 4.7,
        url: "https://substack.com/",
    },
    {
        id: 4,
        title: "History Untold",
        host: "Emma Knight",
        description: "Uncovering lesser-known stories from the past.",
        thumbnail: "https://images.unsplash.com/photo-1488376986648-2512dfc6f736?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 22,
        category: "History",
        rating: 4.9,
        url: "https://substack.com/",
    },
    {
        id: 5,
        title: "Sports Zone Weekly",
        host: "Mike Anderson",
        description: "Weekly analysis and updates on the world of sports.",
        thumbnail: "https://images.unsplash.com/photo-1531651008558-ed1740375b39?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 77,
        category: "Sports",
        rating: 4.6,
        url: "https://substack.com/",
    },
    {
        id: 6,
        title: "Code & Coffee",
        host: "Tanya Smith",
        description: "A casual chat on coding, development, and tech careers.",
        thumbnail: "https://images.unsplash.com/photo-1488376986648-2512dfc6f736?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 41,
        category: "Programming",
        rating: 4.8,
        url: "https://substack.com/",
    },
    {
        id: 7,
        title: "Cosmic Conversations",
        host: "David Lee",
        description: "Discussions about space, astronomy, and the universe.",
        thumbnail: "https://images.unsplash.com/photo-1531651008558-ed1740375b39?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 29,
        category: "Science",
        rating: 4.9,
        url: "https://substack.com/",
    },
    {
        id: 8,
        title: "Cinema Chat",
        host: "Lily Brooks",
        description: "Deep dives into movies, TV shows, and entertainment.",
        thumbnail: "https://images.unsplash.com/photo-1488376986648-2512dfc6f736?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 65,
        category: "Entertainment",
        rating: 4.7,
        url: "https://substack.com/",
    },
];
