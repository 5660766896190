import React, { useEffect, useState } from 'react'
import ComingBackSoon from './ComingBackSoon'
import PageHeader from '../components/PageHeader'
import HeroSection from '../components/HeroSection';
import MarketsSection from '../components/MarketsSection';
import TechSection from '../components/TechSection';
import NewLetterCTA from '../components/NewLetterCTA';
import { Link } from 'react-router-dom';
import { CategoriesList } from '../components/CategoriesList';
import PodcastCTA from '../components/PodcastCTA';
import { fetchArticles, fetchCategories, fetchPodcasts } from '../services/api';
import SideBar from '../components/SideBar';

const HomePageContent = ({ articles, podcasts, categories }) => {

    return (
        <div>
            <PageHeader />
            <HeroSection articles={articles} podcasts={podcasts} />
            <div className="home-articles container">
                <div className="articles-container">
                    <MarketsSection articles={articles} />
                    <NewLetterCTA />
                    <TechSection articles={articles} />
                </div>
                <SideBar />
            </div>
        </div>
    )
}

const HomePage = () => {
    const [articles, setArticles] = useState([])
    const [podcasts, setPodcasts] = useState([])
    const [categories, setCategories] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchArticles()
            if (response.success) {
                setArticles(response.articles)
            }
        }

        fetchData()

        const fetchPodcastsData = async () => {
            const response = await fetchPodcasts()
            if (response.success) {
                setPodcasts(response.podcasts)
            }
        }
        fetchPodcastsData()

        const fetchCategoriesData = async () => {
            const response = await fetchCategories()
            if (response.success) {
                setCategories(response.categories)
            }
        }
        fetchCategoriesData()

    }, [])
    return (
        <HomePageContent articles={articles} podcasts={podcasts} categories={categories} />
    )
}
export default HomePage


