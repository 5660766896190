export const categoriesList = [
    {
        id: 1,
        name: "Podcasts",
        count: 8,
        slug: "podcasts",
    },
    {
        id: 2,
        name: "NewsLetter",
        count: 5,
        slug: "newsletter",
    },
    {
        id: 3,
        name: "Technology",
        count: 12,
        slug: "technology",
    },
    {
        id: 4,
        name: "Markets",
        count: 7,
        slug: "markets",
    },
    {
        id: 5,
        name: "Games",
        count: 3,
        slug: "Games"
    }
]