import React from 'react';

const BusinessBlogPost = () => {
  const tableData = [
    {
      metric: "Digital Investment (Billions)",
      y2022: "$1,300",
      y2023: "$1,800",
      y2024: "$2,300",
    },
    {
      metric: "AI Adoption Rate",
      y2022: "35%",
      y2023: "48%",
      y2024: "65%",
    },
    {
      metric: "Remote Work Integration",
      y2022: "45%",
      y2023: "60%",
      y2024: "75%",
    },
  ];

  return (
    <div className="blog-container">
      <article className="blog-content">
        <h1>The Evolution of Digital Transformation in Modern Business</h1>

        <img
          src="https://images.unsplash.com/photo-1665686304355-0b09b1e3b03c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="Digital Business Transformation"
          className="hero-image"
        />

        <p>
          In today's rapidly evolving business landscape, digital transformation has become more than just a buzzword—it's a fundamental necessity for survival and growth. As we navigate through 2024, organizations across the globe are reimagining their operations through the lens of digital innovation.
        </p>

        <h2>The Impact of AI on Business Operations</h2>
        <p>
          Artificial Intelligence has revolutionized how businesses operate, from customer service to supply chain management. Companies implementing AI solutions have reported significant improvements in efficiency and decision-making processes.
        </p>

        <img
          src="/api/placeholder/800/400"
          alt="AI Implementation in Business"
          className="content-image"
        />

        <h2>Key Digital Transformation Statistics</h2>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Metric</th>
                <th>2022</th>
                <th>2023</th>
                <th>2024 (Projected)</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.metric}</td>
                  <td>{row.y2022}</td>
                  <td>{row.y2023}</td>
                  <td>{row.y2024}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h2>Remote Work Revolution</h2>
        <p>
          The shift to remote work has permanently altered the business landscape. Companies are now investing heavily in digital collaboration tools and virtual office environments to maintain productivity and team cohesion.
        </p>

        <iframe width="100%" height="579" src="https://www.youtube.com/embed/0Q4BZhTVSqk" title="How To Start a Business - Step by Step Guide for Beginners" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <h2>Looking Ahead: Future Trends</h2>
        <p>
          The metaverse is opening new opportunities for virtual commerce and collaboration. Blockchain technology is revolutionizing supply chain transparency and financial transactions. Sustainable technology practices are becoming increasingly important as businesses align with environmental goals.
        </p>

        <style jsx>{`
          .blog-container {
            margin: 0 auto;
            padding: 20px;
            font-family: system-ui, -apple-system, sans-serif;
            color: #333;
            background-color: white;
          }

          .blog-content {
            width: 100%;
          }

          p {
            font-size: 1.1rem;
            line-height: 1.6;
            margin-bottom: 1.5rem;
          }

          .hero-image {
            width: 100%;
            max-height: 400px;
            object-fit: cover;
            border-radius: 8px;
            margin-bottom: 2rem;
          }

          .content-image {
            width: 100%;
            border-radius: 8px;
            margin: 1.5rem 0;
          }

          .table-container {
            overflow-x: auto;
            margin: 2rem 0;
          }

          table {
            border-collapse: collapse;
            margin: 1rem 0;
            background-color: white;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
          }

          th, td {
            padding: 12px 15px;
            text-align: left;
            border-bottom: 1px solid #ddd;
          }

          th {
            background-color: #2c2c2c;
            color: white;
          }

          tr:nth-child(even) {
            background-color: #f8f8f8;
          }

          .video-container {
            position: relative;
            padding-top: 56.25%; /* 16:9 Aspect Ratio */
            margin: 2rem 0;
          }

          .video-container video {
            position: ;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }

          @media (max-width: 768px) {
            .blog-container {
              padding: 15px;
            }
            p {
              font-size: 1rem;
            }

            th, td {
              padding: 8px 10px;
            }
          }
        `}</style>
      </article>
    </div>
  );
};

export default BusinessBlogPost;