import { ArrowRight01Icon } from "hugeicons-react"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { categoriesList } from "../constants/categories"

export const CategoriesList = ({ categories }) => {
    const navigate = useNavigate()

    const handleNavigate = (e, category) => {
        e.preventDefault()
        const title = "Articles in"
        const highlight = category.name
        const slug = category.slug
        navigate(`/articles/categories/${category.slug}/`, { state: { title: title, highlight: highlight, slug: slug } })
    }
    return (
        <div className="categories-list">
            <h2 className="section-title">To <span>Categories</span></h2>
            <div className="categories-grid">
                {
                    categories &&
                    categories.map((category, index) => (
                        <Link key={index} className="category" onClick={(e) => handleNavigate(e, category)}>
                            <div className="link">
                                <ArrowRight01Icon className="icon" size={20} />
                                <span>{category.name}</span>
                            </div>
                            <div className="number">{category.count}</div>
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}
