import { Cancel01Icon, InformationCircleIcon, InstagramIcon, Linkedin01Icon, Moon01Icon, NewTwitterIcon, PencilEdit01Icon, Search01Icon, Sun03Icon } from 'hugeicons-react'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { fetchCategories } from '../services/api'
import SearchArticles from './SearchArticles'

const PageHeader = () => {
    const [expandSearch, setExpandSearch] = useState(false)
    const [expandMenu, setExpandMenu] = useState(false)
    const [categories, setCategories] = useState([])
    const navigate = useNavigate()

    const handleNavigate = (e, category) => {
        e.preventDefault()
        const title = "Articles in"
        const highlight = category.name
        const slug = category.slug
        navigate(`/articles/categories/${category.slug}/`, { state: { title: title, highlight: highlight, slug: slug } })
    }

    const toggleSearch = () => {
        setExpandSearch(!expandSearch)
    }

    const toggleMenu = () => {
        setExpandMenu(!expandMenu)
    }


    useEffect(() => {
        const fetchCategoriesData = async () => {
            const response = await fetchCategories()
            if (response.success) {
                setCategories(response.categories)
            }
        }
        fetchCategoriesData()
    }, [])
    return (
        <>
            <header className={`page-header ${expandMenu ? 'nav-open' : ''}`}>
                <div className="note">
                    <div className="container">
                        <InformationCircleIcon />
                        You are viewing an under development version
                    </div>
                </div>
                <nav className={`navigation container`}>
                    <a href='/' className="branding">
                        <img src="/logo512.png" alt="logo" className="logo" />
                        <h1 className="site-title">The KFinancial</h1>
                    </a>
                    <div className="categories">
                        {
                            categories && categories.map((category, index) => (
                                <p className='link' key={index} onClick={(e) => handleNavigate(e, category)}>{category.name}</p>
                            ))
                        }
                    </div>
                    <div className="mobile-search">
                        <Search01Icon color='#00AAA1' size={32} className='search' onClick={toggleSearch} />
                    </div>
                    <div className="actions">
                        <Search01Icon color='#00AAA1' className='search' onClick={toggleSearch} />
                        {/* <PencilEdit01Icon /> */}
                        {/* <Link>About</Link>
                    <Link>Contact</Link> */}
                        <div className="social-icons">
                            <a href="https://www.linkedin.com/posts/the-k-financial-news_a-look-at-the-cost-of-borrowing-in-uganda-activity-7241687423443714048-ejmT?utm_source=share&utm_medium=member_ios"><Linkedin01Icon /></a>
                            <a href="https://www.instagram.com/thekfinancial?igsh=NXM3MWV1aGZ4OXU="><InstagramIcon /></a>
                            <a href="https://x.com/kfinancialnews?s=11"><NewTwitterIcon /></a>
                        </div>
                    </div>

                    <div onClick={toggleMenu} className={`menu-icon ${expandMenu ? 'menu-open' : ''}`}>
                        <div className="line a"></div>
                        <div className="line b"></div>
                        <div className="line c"></div>
                    </div>
                </nav>
                {
                    expandSearch &&
                    <SearchArticles toggleSearch={toggleSearch} />
                }
            </header>
        </>
    )
}

export default PageHeader
